<template>
  <v-dialog
    :max-width="listWidth"
    v-model="dialog"
    @input="dialogChanged"
  >
    <div>
      <v-row
        max-height="100vh"
        no-gutters
      >
        <v-col
          class="pt-0 pb-0 pr-0 grey lighten-3"
          cols="12"
          :md="listColumns"
          no-gutters
        >
          <v-card
            tile
            flat
            max-height="25vh"
            :loading="dashTypes === null"
          >
            <v-card-title>{{ $t("dashTypes") }}</v-card-title>
            <v-card-subtitle class="pb-4">
              {{ $t("selectDashType") }}
            </v-card-subtitle>
            <v-text-field
              fixed
              class="px-6 pb-2"
              v-model="searchQuery"
              :label="$t('search')"
              outlined
              dense
              clearable
              hide-details
            />
          </v-card>
          <v-card
            tile
            flat
            max-height="73vh"
            class="overflow-y-auto"
            v-if="dashTypes !== null"
          >
            <v-list class="pa-0 ma-0">
              <v-list-item
                v-if="filteredDashTypes.length === 0"
                class="pl-7 pr-7"
              >
                <v-list-item-subtitle>
                  {{
                    $t("noMatchesFound")
                  }}
                </v-list-item-subtitle>
              </v-list-item>
              <template v-for="(dashType, index) in filteredDashTypes">
                <v-list-item
                  link
                  class="pl-7 pr-7"
                  :key="dashType.namespace"
                  @click="select(dashType)"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ dashType.title }}</v-list-item-title>
                    <v-list-item-subtitle>
                      {{
                        dashType.description
                      }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider
                  :key="index"
                  v-if="index < dashTypes.length - 1"
                />
              </template>
            </v-list>
          </v-card>
        </v-col>
        <v-col
          v-if="selectedDashType"
          no-gutters
          cols="8"
          class="grey lighten-3 pa-2"
        >
          <v-card
            outlined
            class="elevation-0 m mb-1"
          >
            <v-card-title>
              {{ $t("dashPreviewTitle") }}
            </v-card-title>
            <v-card-text>
              <v-text-field
                dense
                :label="this.$t('title')"
                v-model="selectedDashType.title"
              />
              <v-text-field
                dense
                :label="this.$t('description')"
                v-model="selectedDashType.description"
              />
              <v-slider
                dense
                :color="primaryColor"
                v-model="selectedDashType.cols"
                min="1"
                max="12"
                :label="$t('size')"
              />
              <v-card-text
                class="pa-0 ma-0"
                v-if="configFieldsExist"
              >
                <v-row
                  dense
                  class="pa-0 ma-0"
                >
                  <template
                    v-for="configField in selectedDashType.configFields"
                  >
                    <v-col
                      :key="configField.key + selectedDashType.namespace"
                      :cols="12 / selectedDashType.configFields.length"
                    >
                      <div :key="configField.key + '-title'">
                        {{ $t("additionalSettingsForDash") }}
                      </div>
                      <ConfigField
                        :key="configField.key"
                        :field="configField"
                        :instance="selectedDashType"
                      />
                    </v-col>
                  </template>
                </v-row>
              </v-card-text>
              <v-card-text
                dense
                class="pa-0 ma-0"
                v-if="!configFieldsExist"
              >
                {{ $t("dashProvidesNoAdditionalSettings") }}
              </v-card-text>
            </v-card-text>
            <v-card-actions>
              <v-btn
                class="primary"
                @click="create"
                :loading="processing"
                :disabled="processing"
                small
                block
              >
                {{ $t("create") }}
              </v-btn>
            </v-card-actions>
          </v-card>
          <TransientDash
            :key="selectedDashType.namespace"
            :forced-chart-height="chartHeight"
            :title="selectedDashType.title"
            :description="selectedDashType.description"
            :namespace="selectedDashType.namespace"
            :config="{}"
            :fixed-header="true"
            disclaimer=""
            :channels="null"
            :locations="null"
            :start-time-in-millis="lastWeekInMillis"
            :end-time-in-millis="todayInMillis"
          />
        </v-col>
      </v-row>
    </div>
  </v-dialog>
</template>

<script>
import TransientDash from "@/components/dash/TransientDash"
import ConfigField from "@/components/ConfigField"

export default {
	name: "DashTypesDialog",
	components: { ConfigField, TransientDash },
	data() {
		return {
			dialog: true,
			searchQuery: "",
			selectedDashType: null,
			newDash: null,
			processing: false,
			oneDayInMillis: 86400000,
		}
	},
	methods: {
		getDashTypes() {
			return this.$store.dispatch("getDashTypes")
		},
		dialogChanged() {
			this.$emit("input", this.dialog)
		},
		select(dashType) {
			this.selectedDashType = {
				title: dashType.title,
				description: dashType.description,
				namespace: dashType.namespace,
				cols: 12,
				weight: 0,
				data: {},
				configFields: dashType.configFields,
			}
		},
		create() {
			this.processing = true
			this.$store
				.dispatch("createDash", {
					title: this.selectedDashType.title,
					description: this.selectedDashType.description,
					namespace: this.selectedDashType.namespace,
					cols: this.selectedDashType.cols,
					data: this.selectedDashType.data,
				})
				.then((createdDash) => {
					this.$emit("created", createdDash)
					this.$emit("close")
				})
				.finally(() => {
					this.processing = false
				})
		},
		cancel() {
			this.selectedDashType = null
		},
	},
	computed: {
		todayInMillis() {
			return new Date().getTime()
		},
		lastWeekInMillis() {
			return new Date().getTime() - this.oneDayInMillis * 7
		},
		configFieldsExist() {
			return (
				this.selectedDashType !== null &&
				typeof this.selectedDashType !== "undefined" &&
				this.selectedDashType.configFields !== null &&
				this.selectedDashType.configFields.length > 0 &&
				!this.selectedDashType.configFields.includes(null)
			)
		},
		primaryColor() {
			return this.$store.getters.primaryColor
		},
		listWidth() {
			if (this.selectedDashType) {
				return "1200px"
			}
			return "400px"
		},
		chartHeight() {
			if (this.configFieldsExist) {
				return "35vh"
			}
			return "46vh"
		},
		listColumns() {
			if (this.selectedDashType) {
				return "4"
			}
			return "12"
		},
		dashTypes() {
			return this.$store.state.dashTypes
		},
		filteredDashTypes() {
			if (
				typeof this.searchQuery === "undefined" ||
				this.searchQuery === null ||
				this.searchQuery === ""
			) {
				return this.dashTypes
			}
			return this.dashTypes.filter((dash) => {
				if (typeof dash === "undefined" || dash === null) {
					return false
				}
				if (typeof dash.title !== "undefined" && dash.title !== null) {
					if (
						dash.title.toLowerCase().includes(this.searchQuery.toLowerCase())
					) {
						return true
					}
				}
				if (
					typeof dash.description !== "undefined" &&
					dash.description !== null
				) {
					if (
						dash.description
							.toLowerCase()
							.includes(this.searchQuery.toLowerCase())
					) {
						return true
					}
				}
				return false
			})
		},
	},
}
</script>
